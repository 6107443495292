.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background: rgba(170, 215, 168, 1);
  background-image: url("/christmas.png");
  font-weight: bold;
}

.glow {
  font-size: 40px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #e60073, 0 0 8px #e60073, 0 0 10px #e60073, 0 0 12px #e60073, 0 0 14px #e60073;
  }
  
  to {
    text-shadow: 0 0 4px #fff, 0 0 6px #ff4da6, 0 0 8px #ff4da6, 0 0 10px #ff4da6, 0 0 12px #ff4da6, 0 0 14px #ff4da6, 0 0 16px #ff4da6;
  }
}

.nav.nav-tabs {
  padding-top: 10px;
  background: rgba(170, 215, 168, 1);
  align-items: center;
  justify-content: center;
}

.nav-link {
  color: black;
  font-weight: bold;  
}

.App-link {
  color: #09d3ac;
}

.Game {
  padding-top: 3px;
}
.PlayButtons {
  padding-top: 3px;
}

.btn.btn-primary.btn-lg {
  margin: 5px;
}

.btn.btn-secondary.btn-lg {
  margin: 5px;
}

.Dual {
  font-weight: bold;
  font-size: xx-large;
  background: rgba(170, 215, 168, 0.6);
  padding-left: 50px;
  padding-right: 50px;
}