.field{
    background-color: lightcyan;
    border: 1px solid black;
    margin: 2px;
    max-height: 50%;
    width: 50%;
}
.field:after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.row-game {
    display: flex;
    flex-wrap: wrap;
}

.container{
    max-width: 100% !important;
}

.Container{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.Grid{
    margin: 0;
    height: 640px;
    width: 700px;
}

.scoreTable {
  display: block;
  height: 400px;
  overflow-y: scroll;
}


.highlight{
    background-color: pink;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1114px) {
    .field{
        height: 120px;
    }

    .Container{
        padding-left: 80px;
        padding-right: 80px;
    }

    .Grid{
        margin: 0;
        height: auto;
        width: auto;
    }
  }
